import styled, { createGlobalStyle }  from 'styled-components'

export const GlobalStyleHome = createGlobalStyle`
  .full-height{
    height: 100%;
  }
`
export const ImgLogo = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  src: url(${props => props.src});
`
