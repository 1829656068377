import React from 'react';
import {Column, Columns} from "bloomer";
import Logotipo from '../../images/logotipo.jpg';
import {ImgLogo} from './Home.styles';
import {GlobalStyle} from '../../styles/GlobalStyles';
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Home = () => {

  return (
    <div className="cont-page page-home">
      <GlobalStyle/>
      <Columns className="columns-responsive full-height" isMultiline={true}>
        <Column isSize={{mobile: 0, tablet: 0, desktop: 4, widescreen: 4}}>
          &nbsp;
        </Column>
        <Column isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}} className={'flex-center'}>
          <AniLink
            cover
            to={'/video'}
          >
            <ImgLogo src={Logotipo} alt={''}/>
          </AniLink>

        </Column>
        <Column isSize={{mobile: 0, tablet: 0, desktop: 4, widescreen: 4}}>
          &nbsp;
        </Column>
      </Columns>
    </div>
  )
}
export default Home;
