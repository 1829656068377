import * as React from "react"
import Layout from '../components/Layout'
import Seo from "../components/seo"
import Home from '../components/Home';

const IndexPage = () => {
  return (
    <Layout
      clsOverflow={false}
    >
      <Seo
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
      />

      <Home/>

    </Layout>
  )
}

export default IndexPage
